import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  buttons: {
    m: 1
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact NINS
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='About the Newman Review'>
                <Text variant='p'>
                  The Newman Review is an e-publication of the National
                  Institute for Newman Studies (NINS) featuring scholarly,
                  original articles on John Henry Newman’s legacy and its
                  relevance for today. Manuscripts demonstrating critical
                  engagement with Newman’s life and thought are encouraged. The
                  Newman Review is internally reviewed by NINS academic staff.
                  NINS was conceived as a forum for exchanging ideas and
                  discoveries about Newman’s thought and its continuing
                  relevance, and the Newman Review is an extension of that
                  mission. The Newman Review complements the Newman Studies
                  Journal, NINS Digital Collections, NINS Scholars Program, and
                  the Gailliot Center for Newman Studies in Pittsburgh,
                  Pennsylvania.
                </Text>
                <Button as={GLink} to='/submission'>
                  Submissions
                </Button>
                <Button
                  sx={styles.buttons}
                  as={GLink}
                  to='https://www.newmanstudies.org/'
                >
                  NINS
                </Button>
              </Section>
              <br />
              <br />
              <Section title='The Newman Studies Journal'>
                <Text variant='p'>
                  Founded in 2004 by the National Institute for Newman Studies,
                  the Newman Studies Journal (NSJ) is an interdisciplinary
                  research publication dedicated to the life, work, and thought
                  of John Henry Newman and their continuing significance. The
                  NSJ welcomes submissions that explore Newman’s life and works,
                  his predecessors, contemporaries and successors, his reception
                  throughout history, and his influence today. It also features
                  studies that situate or adopt Newman’s approach within current
                  scholarly or critical debate on a wide variety of topics.
                  Additionally, the NSJ includes reviews of Newman-related
                  publications. Newman Studies Journal is a peer-reviewed
                  publication under the supervision of the editors with
                  assistance from an editorial board, composed of noted Newman
                  scholars. The National Institute for Newman Studies holds the
                  copyright for Newman Studies Journal.
                </Text>
                <Button as={GLink} to='https://newmanstudies.org/journal'>
                  Newman Studies Journal
                </Button>
              </Section>
              <br />
              <br />
              <Section title='NINS Digital Collections'>
                <Text variant='p'>
                  NINS digital collections contain the most comprehensive
                  archive of works authored by John Henry Newman in the world,
                  including 500,000+ digitized images of Newman's handwritten
                  papers, 3000+ books and articles published by (or about) John
                  Henry Newman, and an extensive database of the card catalog
                  used by Newman to check out/in books from the Oriel College
                  Library. NINS Digital Collections also includes the popular
                  Newman Reader, which includes HTML version of Newman's primary
                  works.
                </Text>
                <Button
                  as={GLink}
                  to='https://digitalcollections.newmanstudies.org/'
                >
                  NINS Digital Collections
                </Button>
              </Section>
              <br />
              <br />
              <Section title='Visiting Scholars Program'>
                <Text variant='p'>
                  The National Institute for Newman Studies (NINS) offers a
                  Visiting Scholars Program for scholars who wish to devote a
                  period of research, 2 to 8 weeks, and writing in the field of
                  Newman Studies. Candidates for this program are admitted at
                  two levels: $2,000 or $1,200 stipend, plus lodging at the
                  institute. Qualification for these two categories are based on
                  the following criteria.
                </Text>
                <Button
                  as={GLink}
                  to='https://www.newmanstudies.org/scholars-program'
                >
                  NINS Scholars Program
                </Button>
              </Section>
              <br />
              <br />
              <Section title='Gailliot Center for Newman Studies'>
                <Text variant='p'>
                  Building on a bequest made by Fr. Giese, NINS has a print book
                  collection with over 4000 volumes, with additions being made
                  regularly to acquire the most recent Newman scholarship. The
                  library is located at the Gailliot Center for Newman Studies
                  in Pittsburgh, Pennsylvania, and is available to
                  researchers-in-residence through the Visiting Scholars Program
                  and, by special arrangement, to others who have an in interest
                  in Newman.
                </Text>
                <Button as={GLink} to='https://www.newmanstudies.org/library'>
                  NINS Library
                </Button>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
